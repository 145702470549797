@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700|Roboto+Slab:400,700&display=swap');

.wrapper {
    margin: 15px;
}

.container {
    padding: 30px;
}

code {
    font-size: 50px;
}