@import url(https://fonts.googleapis.com/css?family=Oxygen:400,700|Roboto+Slab:400,700&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.wrapper {
    margin: 15px;
}

.container {
    padding: 30px;
}

code {
    font-size: 50px;
}
